// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getFirestore} from "firebase/firestore"
const firebaseConfig = {
    apiKey: "AIzaSyBLQ6xMljgcUEhhmgJqIFb8ygSIG-WzflQ",
    authDomain: "weatherapp-e20cd.firebaseapp.com",
    projectId: "weatherapp-e20cd",
    storageBucket: "weatherapp-e20cd.appspot.com",
    messagingSenderId: "765026666026",
    appId: "1:765026666026:web:9e48edf55b0e869b1ae553"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db =getFirestore(app);