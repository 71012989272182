

function Terms(){


    return (<div style={{display:"flex", flexDirection:"column" , color:"black",justifyContent:"center",width:"100%"}}>
       <h4> **Welcome to [Aioonc]!**</h4>

<h4>These Terms and Conditions ("Terms") outline the rules for using our website ("Website" or "Service"). By accessing or using the Website, you agree to be bound by these Terms.</h4>
<h4>**What We Offer:**</h4>
<h4>* We provide weather information, including forecasts, current conditions, and historical data.</h4>
<h4>* We strive to offer accurate information, but we cannot guarantee it. </h4>
<h4>* Our data comes from various sources, and errors might occur.</h4>
<h4>**What We Expect from You:**</h4>
<h4>* You must be at least 13 years old to use the Website.</h4>
<h4>* You agree to use the Website for lawful purposes only.</h4>
<h4>* You won't do anything that could damage or interfere with the Website or its operation.</h4>
<h4>* You are responsible for maintaining the confidentiality of your account (if you create one).</h4>
<h4>**Limitations:**</h4>
<h4>* We are not liable for any decisions you make based on the weather information we provide.</h4>
<h4>* We are not responsible for any damages or losses caused by inaccurate weather information or service interruptions.</h4>
<h4>**Privacy:**</h4>
<h4>* We respect your privacy. </h4>
<h4>**Changes to These Terms:**</h4>
<h4>* We may update these Terms from time to time. We will notify you of any significant changes by posting the updated Terms on the Website.</h4>
<h4>**Termination:**</h4>
<h4>* We may terminate your access to the Website for violating our Terms.
</h4>
    </div>);
}

export default Terms;