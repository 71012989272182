import sunny from "./imgs/sunny.png"
import rain from "./imgs/rain.png"
import cloudy_sun from "./imgs/cloudy-sun.png"
import cloud from "./imgs/cloud.png"
import moon from "./imgs/moon.png"

import cloudy_night from "./imgs/cloudy-night.png"




export function Weather_icon(weather ,sorn){
    

    if( sorn == 1 && weather == "Clear"){
        return (<img src={sunny} style={{width:"50px"}}></img>);

    }

    if( sorn == 1 && weather == "Clouds"){
        return (<img src={cloudy_sun}style={{width:"50px"}}></img>);

    }

    if( sorn == 0 && weather == "Clear"){
        return (<img src={moon}style={{width:"50px"}}></img>);

    }

    if( sorn == 0 && weather == "Clouds"){
        return (<img src={cloudy_night}style={{width:"50px"}}></img>);

    }

    if(  weather == "Rain"){
        return (<img src={rain}style={{width:"50px"}}></img>);

    }
    else{
        return (<img src={cloud}style={{width:"50px"}}></img>);

    }
}