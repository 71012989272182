import { useState } from "react"

function Rain(rain){



    if((rain) > 0){
        return (                                <div id="w_t_s_r"><img src="https://cdn-icons-png.flaticon.com/512/12276/12276721.png"></img> <h4>{rain}%</h4> </div>
    );
    }

    else{
        return (                                <div id="w_t_s_r"><img src="https://cdn-icons-png.flaticon.com/512/12276/12276721.png"></img> <h4>{rain}%</h4> </div>
    );
    }
}

export default Rain;