import { Filter_tz } from "./Filter_tz";

export function sorn(time,rise,set) {


    var timee= new Date((time )*1000);
    var rise= new Date(rise * 1000 );
    var set= new Date(set * 1000 );
 


    if((timee.getUTCHours()) > rise.getUTCHours() && timee.getUTCHours() < set.getUTCHours() ){
        return 1;
    }
    else{
        return 0;
    }
    
}