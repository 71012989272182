export function Filter_tz(time , tz){
    var cur_tz= new Date();
    cur_tz= (cur_tz.getTimezoneOffset()) *60 * -1;
 



    if (cur_tz == tz){

        return time +cur_tz  ;
    }
    else {
        return (time +tz ) ;
    }
}