export function formatTimestampToTime(timestamp ) {

 
  const dateObj = new Date((timestamp )   *1000 ); // Convert to milliseconds
  // const dateObj =dateObj.lo
   const hours = dateObj.getUTCHours() ; // Get hours in 24-hour format
   const minutes = dateObj.getMinutes().toString().padStart(2, '0'); // Pad minutes with leading zero if needed
   const ampm = hours >= 12 ? 'pm' : 'am'; // Determine AM/PM
   // Adjust for 12-hour format and handle edge cases (midnight/noon)
   const adjustedHours = hours % 12 || 12;  // Adjust for 12-hour clock (or 12 for midnight/noon)
 
   return `${adjustedHours}:${minutes} ${ampm}`;
 

}

