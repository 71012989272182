 export function formatDateToString(dateString) {
  
  
    try {
      // Parse the date-time string
      const dateObj = new Date(dateString);
  
      // Extract day of the week (0-6, Sunday = 0)
      const dayOfWeek = dateObj.getDay();
  
      // Create an array of day names
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  
      // Get the abbreviated day name
      const dayName = days[dayOfWeek];
  
      // Extract date (day of the month)
      const date = dateObj.getDate();
  
      // Extract month (0-11, January = 0)
      const month = dateObj.getMonth();
  
      // Create an array of month names
      const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  
      // Get the abbreviated month name
      const monthName = months[month];
  
      // Format the final string
      return `${dayName}, ${date} ${monthName}`;
    } catch (error) {
      console.error("Invalid date-time string format:", error);
      return null; // Or return any appropriate default value if parsing fails
    }
  }

